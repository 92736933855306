import {
  Nullable,
  Undefinable,
  SessionInterface,
  BusinessUnitInterface,
  PaymentMethodInterface,
} from "../../interfaces";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInterface } from "../../interfaces/UserInterface";

export interface UserState {
  businessUnit?: BusinessUnitInterface;
  paymentMethods?: PaymentMethodInterface[];
  retentionMethods?: PaymentMethodInterface[];
  user?: Nullable<UserInterface>;
  userLoginStartTime?: number; // milliseconds
  userSession?: Nullable<SessionInterface>;
}
export interface UserSessionInterface {
  user: UserInterface;
  session: SessionInterface;
}

const initialState: UserState = {
  user: null,
  userLoginStartTime: 0,
  userSession: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLocation: (
      state,
      action: PayloadAction<Undefinable<BusinessUnitInterface>>
    ) => {
      state.businessUnit = action.payload;
    },
    setPaymentMethods: (
      state,
      action: PayloadAction<PaymentMethodInterface[]>
    ) => {
      state.paymentMethods = action.payload;
    },
    setRetentionMethods: (
      state,
      action: PayloadAction<PaymentMethodInterface[]>
    ) => {
      state.retentionMethods = action.payload;
    },
    login: (state, action: PayloadAction<UserSessionInterface>) => {
      state.user = action.payload.user;
      state.userSession = action.payload.session;
      state.userLoginStartTime = new Date().getTime();
    },
    logout: (state) => {
      state.user = null;
      state.businessUnit = undefined;
      state.userLoginStartTime = undefined;
      state.userSession = null;
    },
  },
});

export const {
  setUserLocation,
  setPaymentMethods,
  setRetentionMethods,
  login,
  logout,
} = userSlice.actions;

export default userSlice.reducer;
