import api from "./api";
import axios, { AxiosError } from "axios";
import moment from "moment";
import { Nullable, SessionInterface } from "../interfaces";
import { UserInterface } from "../interfaces/UserInterface";
import { UserPasswordUpdateRequestDto } from "../interfaces/Dtos/UserPasswordUpdateRequestDto";
import {
  ErrorInterface,
  ResponseInterface,
} from "../interfaces/MessageInterface";
import {
  SessionTimeInMin,
  mapBusinessUnitDtoListToBusinessUnitInterface,
} from "../utils";

const baseURL = process.env.REACT_APP_API_HOST;

export const userLogin = async (
  emailOrLogin: string,
  password: string,
  appCode: string
): Promise<Nullable<UserInterface> | ErrorInterface> => {
  const body = JSON.stringify({
    Email: emailOrLogin,
    Login: emailOrLogin,
    Password: password,
  });
  const uri = `${baseURL}/Account/Login?withUserDetails=true&applicationCode=${appCode}`;
  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const user = response.data;
      user.businessUnitList = mapBusinessUnitDtoListToBusinessUnitInterface(
        user.businessUnitList
      );
      return user;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError: AxiosError = error;
      if (axiosError.response?.status === 400) {
        return axiosError.response.data as ErrorInterface;
      }
    } else {
      console.log("there was an error", error);
    }
  }
  return null;
};

// Service to update user password
export const changePassword = async (
  request: UserPasswordUpdateRequestDto
): Promise<ResponseInterface<null>> => {
  const body = JSON.stringify(request);
  const uri = `${baseURL}/Users/UpdatePassword`;

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response.data;
    }
    return {
      model: null,
      didError: true,
      message: `Server status: ${response.status}`,
      errorMessage: "Server error",
    };
  } catch (error) {
    return {
      model: null,
      didError: true,
      message: `Error: ${error}`,
      errorMessage: "Application error 5",
    };
  }
};
// TODO add API endpoint call
export const forgotPassword = async (
  email: string,
  appCode: string
): Promise<ResponseInterface<null>> => {
  const body = JSON.stringify({
    Login: email,
  });
  const uri = `${baseURL}/Users/ResetPassword?userEmail=${email}&applicationCode=${appCode}`;

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      return response.data;
    }
    return {
      model: null,
      didError: true,
      message: `Server status: ${response.status}`,
      errorMessage: "Server error",
    };
  } catch (error) {
    return {
      model: null,
      didError: true,
      message: `Error: ${error}`,
      errorMessage: "Application error 6",
    };
  }
};
// InsertSession method
export const CreateOrUpdateSession = async (
  userID: string,
  applicationCode: string,
  buCode: string
): Promise<ResponseInterface<SessionInterface>> => {
  const body = JSON.stringify({
    UserID: userID,
    ApplicationCode: applicationCode,
    BuCode: buCode,
  });
  const uri = `${baseURL}/Account/InsertSession?sessionTimeMinutes=${SessionTimeInMin}`;

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      //map Date strings to Date objects
      const session = response.data.model;
      if (!!session) {
        session.validFrom = moment(session.validFrom).toDate().getTime();
        session.validUntil = moment(session.validUntil).toDate().getTime();
        localStorage.setItem("SessionID", session.sessionID);
        localStorage.setItem("UserID", session.userID);
        localStorage.setItem("RefreshToken", session.tokenHash);
      }
      response.data.model = session;
      return response.data;
    }
    return {
      model: null,
      didError: true,
      message: `Server status: ${response.status}`,
      errorMessage: "Server error",
    };
  } catch (error) {
    return {
      model: null,
      didError: true,
      message: `Error: ${error}`,
      errorMessage: "Application error 7",
    };
  }
};

// LogoutSession method
export const logoutSession = async (
  userID: string,
  sessionID: string
): Promise<ResponseInterface<null>> => {
  const body = JSON.stringify({
    UserID: userID,
    SessionID: sessionID,
  });
  const uri = `${baseURL}/Account/LogoutSession`; // TODO see if this is expected time

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      localStorage.removeItem("RefreshToken");
      localStorage.removeItem("Token");
      localStorage.removeItem("UserID");
      localStorage.clear();
      return response.data;
    }
    return {
      model: null,
      didError: true,
      message: `Server status: ${response.status}`,
      errorMessage: "Server error",
    };
  } catch (error) {
    return {
      model: null,
      didError: true,
      message: `Error: ${error}`,
      errorMessage: "Application error 8",
    };
  }
};
