import api from "./api";
import { mercantilDateFormat, mercantilPhoneFormat } from "../utils";
import moment from "moment";
import {
  ResponseInterface,
  MercantilC2PResponse,
  MercantilC2PKeyResponse,
  MercantilListBatchResponse,
  MercantilListInboxResponse,
  MercantilUploadFileResponse,
  MercantilTransferSearchResponse,
  MercantilMobilePaymentSearchResponse,
} from "../interfaces";

const baseURL = `${process.env.REACT_APP_API_HOST}/Mercantil`;
const header = {
  headers: {
    "Content-Type": "application/json",
  },
};

// Transfer Search Service
export const mercantilTransferSearch = async (
  issuerCustomerId: string,
  date: Date,
  bank: string,
  reference: string,
  amount: number,
  attempts: number
): Promise<ResponseInterface<MercantilTransferSearchResponse>> => {
  let result = {
    message: "",
    didError: true,
    errorMessage: "",
    model: null,
  };

  const uri = `${baseURL}/TransferSearch`;

  while (attempts > 0) {
    try {
      const formattedDate = mercantilDateFormat(date);
      const body = {
        issuerCustomerId,
        date: formattedDate,
        bank,
        reference,
        amount,
      };

      const response = await api.post(uri, body, header);

      result = response.data;
      if (!response.data.didError) {
        break;
      }

      date = moment(date).subtract(1, "days").toDate();
      attempts -= 1;
    } catch (error) {
      result.errorMessage =
        "Hubo un error al recibir la respuesta del banco mercantil";
      break;
    }
  }

  return result;
};

// Mobile Payment Search Service
export const mercantilMobilePaymentSearch = async (
  originMobileNumber: string,
  date: Date,
  reference: string,
  amount: number,
  attempts: number
): Promise<ResponseInterface<MercantilMobilePaymentSearchResponse>> => {
  let result = {
    message: "",
    didError: true,
    errorMessage: "",
    model: null,
  };

  const uri = `${baseURL}/MobilePaymentSearch`;
  const formattedPhone = mercantilPhoneFormat(originMobileNumber);

  while (attempts > 0) {
    try {
      const formattedDate = mercantilDateFormat(date);
      const body = {
        originMobileNumber: formattedPhone,
        date: formattedDate,
        reference,
        amount,
      };

      const response = await api.post(uri, body, header);

      result = response.data;
      if (!response.data.didError) {
        break;
      }

      date = moment(date).subtract(1, "days").toDate();
      attempts -= 1;
    } catch (error) {
      result.errorMessage =
        "Hubo un error al recibir la respuesta del banco mercantil";
      break;
    }
  }

  return result;
};

// C2P Payment Service
export const mercantilC2P = async (
  destinationMobileNumber: string,
  destinationID: string, // V12345678
  customerCode: string,
  invoice: string,
  amount: number,
  bank: string,
  reference?: string, // only for annulation
  annulation: boolean = false
): Promise<ResponseInterface<MercantilC2PResponse>> => {
  let result = {
    message: "",
    didError: true,
    errorMessage: "",
    model: null,
  };

  const uri = `${baseURL}/C2P`;
  const formattedPhone = mercantilPhoneFormat(destinationMobileNumber);
  const body = {
    destinationMobileNumber: formattedPhone,
    destinationID,
    customerCode,
    invoice,
    reference,
    amount,
    bank,
    change: false,
    annulation,
  };

  try {
    const response = await api.post(uri, body, header);
    return response.data;
  } catch (error) {
    result.errorMessage =
      "Hubo un error al recibir la respuesta del banco mercantil";
  }

  return result;
};

// C2P Key Service
export const mercantilC2PKey = async (
  destinationMobileNumber: string,
  destinationID: string // V12345678
): Promise<ResponseInterface<MercantilC2PKeyResponse>> => {
  let result = {
    message: "",
    didError: true,
    errorMessage: "",
    model: null,
  };

  const uri = `${baseURL}/C2PKey`;
  const formattedPhone = mercantilPhoneFormat(destinationMobileNumber);
  const body = {
    destinationMobileNumber: formattedPhone,
    destinationID,
  };

  try {
    const response = await api.post(uri, body, header);
    return response.data;
  } catch (error) {
    result.errorMessage =
      "Hubo un error al recibir la respuesta del banco mercantil";
  }

  return result;
};

// B2P Payment Change Service
export const mercantilChange = async (
  destinationMobileNumber: string,
  destinationID: string, // V12345678
  invoice: string,
  amount: number,
  bank: string
): Promise<ResponseInterface<MercantilC2PResponse>> => {
  let result = {
    message: "",
    didError: true,
    errorMessage: "",
    model: null,
  };

  const uri = `${baseURL}/C2P`;
  const formattedPhone = mercantilPhoneFormat(destinationMobileNumber);
  const body = {
    destinationMobileNumber: formattedPhone,
    destinationID,
    invoice,
    amount,
    bank,
    change: true,
    annulation: false,
  };

  try {
    const response = await api.post(uri, body, header);
    return response.data;
  } catch (error) {
    result.errorMessage =
      "Hubo un error al recibir la respuesta del banco mercantil";
  }

  return result;
};

// TED

// Upload File
export const mercantilUploadTED = async (
  file: File
): Promise<ResponseInterface<MercantilUploadFileResponse>> => {
  let result = {
    message: "",
    didError: true,
    errorMessage: "",
    model: null,
  };

  const uri = `${baseURL}/Upload`;
  const formData = new FormData();
  formData.append("File", file);

  try {
    const response = await api.post(uri, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response.data;
  } catch (error) {
    result.errorMessage =
      "Hubo un error al recibir la respuesta del banco mercantil";
  }

  return result;
};

// Download File
export const mercantilDownloadTED = async (
  fileID: string,
  orderType: string
): Promise<ResponseInterface<MercantilC2PResponse>> => {
  let result = {
    message: "",
    didError: true,
    errorMessage: "",
    model: null,
  };

  const uri = `${baseURL}/Download`;
  const body = {
    fileID,
    orderType,
  };

  try {
    const response = await api.post(uri, body, header);
    return response.data;
  } catch (error) {
    result.errorMessage =
      "Hubo un error al recibir la respuesta del banco mercantil";
  }

  return result;
};

// List Inbox
export const mercantilListInbox = async (
  inboxType: boolean = true // true: entrada, false: salida
): Promise<ResponseInterface<MercantilListInboxResponse>> => {
  let result = {
    message: "",
    didError: true,
    errorMessage: "",
    model: null,
  };

  const uri = `${baseURL}/ListInbox`;
  const body = {
    inboxType: inboxType ? "entrada" : "salida",
  };

  try {
    const response = await api.post(uri, body, header);
    return response.data;
  } catch (error) {
    result.errorMessage =
      "Hubo un error al recibir la respuesta del banco mercantil";
  }

  return result;
};

// List Batch
interface MercantilListBatchParams {
  // Can search by fileId or date range, if fileId and date range are provided, fileId will be used
  fileId?: string;
  fromDate?: Date;
  toDate?: Date;
}
export const mercantilListBatch = async (
  params: MercantilListBatchParams
): Promise<ResponseInterface<MercantilListBatchResponse>> => {
  let result = {
    message: "",
    didError: true,
    errorMessage: "",
    model: null,
  };

  const uri = `${baseURL}/ListBatch`;
  let formattedFromDate: string | undefined;
  let formattedToDate: string | undefined;
  if (params.fromDate) formattedFromDate = mercantilDateFormat(params.fromDate);
  if (params.toDate) formattedToDate = mercantilDateFormat(params.toDate);
  const body = {
    fileId: params.fileId,
    fromDate: formattedFromDate,
    toDate: formattedToDate,
  };

  try {
    const response = await api.post(uri, body, header);
    return response.data;
  } catch (error) {
    result.errorMessage =
      "Hubo un error al recibir la respuesta del banco mercantil";
  }

  return result;
};
