export enum PaymentMethodEnum {
  SUPERADMIN_MERCHANT_P2C = -9,
  SUPERADMIN_MERCHANT_TARJETA_DE_DEBITO = -7,
  MERCHANT = -1,
  CHEQUE = 2,
  TARJETAS_DE_CREDITO,
  TARJETAS_DE_DEBITO,
  PAGO_ELECTRONICO,
  EFECTIVO,
  PAGO_MERCHANT_TARJETA_DE_DEBITO,
  PAGO_MERCHANT_TARJETAS_DE_CREDITO,
  PAGO_MERCHANT_P2C,
  TRANSFERENCIA_BANCARIA_INMEDIATA,
  PAGO_MOVIL_P2C,
  PAGO_MOVIL_C2P,
  PUNTO_DE_VENTA,
  RETENCION_DE_IVA,
  RETENCION_DE_ISRL,
  RETENCION_DE_IMPUESTO_MUNICIPAL,
  DEPOSITO,
  TRANSFERENCIA,
  PAGO_MOVIL_B2P_VUELTO,
  VUELTO_EFECTIVO,
  AJUSTE_DECIMAL,
  NOTA_DE_CREDITO,
  REINTEGRO = 26,
  PAGO_MERCHANT_TARJETA_CONCILIACION_MANUAL,
}

export enum PaymentStatusEnum {
  APPROVE = 51,
  PENDING,
  REJECT,
  CANCELED,
}

export interface PaymentInterface {
  paymentID?: string;
  documentID?: string;
  documentNumber?: string;
  paymentDetailID?: string;
  proofOfPayment?: string;
  reference?: string;
  amount: number;
  paymentAmount?: number;
  igtfAmount: number;
  igtfPercentage: number;
  isRetention: boolean;
  status: number;
  paymentMethod: PaymentMethodInterface;
  clientIdentifier?: string;
  phone?: string;
  bank?: string;
  otp?: string;
  destBankID?: number;
  paymentDate?: string;
  attempts?: number;
  affiliateNumber?: string;
  batchNumber?: string;
  creationUser?: string;
  creationDate?: string;
}

export interface PaymentMethodInterface {
  paymentMethodID: number;
  paymentMethodName: string;
  currencyID: number;
  bankID?: number;
  bankAccountID?: number;
  bankAccounts?: BankAccountInterface[];
}

export interface PaymentMethodDto {
  countryID: number;
  countryName: string;
  buCode: string;
  paymentMethodID: number;
  paymentMethodName: string;
  currencyID: number;
  currencySymbol: string;
  minAmount: number;
  maxAmount: number;
  banks: BankAccountInterface[];
}

export interface BankAccountInterface {
  bankID: number;
  bankCode?: string;
  bankName?: string;
  bankStatus?: number;
  bankAccountID?: number;
  accountNumber?: string;
}
