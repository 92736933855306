import api from "./api";
import { Nullable, CountryInterface } from "../interfaces";

const baseURL = process.env.REACT_APP_API_HOST;

export const getCountries = async (): Promise<Nullable<CountryInterface[]>> => {
  try {
    const result = await api.get(`${baseURL}/Country`);
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {}
  return null;
};
