import "./App.css";
import { FC, useEffect } from "react";
import AppRoutes from "./utils/AppRoutes";
import NotFound from "./modules/NotFound";
import { Routes, Route } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import {
  AppDataInterface,
  CountryInterface,
  UserInterface,
} from "./interfaces";
import {
  alertService,
  getBaseAppData,
  getBusinessUnits,
  getCountries,
} from "./services";
import {
  setInmutableAppData,
  setInmutableBusinessServices,
  setInmutableBusinessUnits,
  setInmutableCountries,
  setInmutableCurrencyList,
  setInmutableExchangeRate,
  setInmutablePaymentModes,
  setInmutablePieceCategories,
  setInmutableRangeDeclareList,
  setInmutableRateItems,
} from "./store/slices";

interface AppProps {}
const App: FC<AppProps> = () => {
  const dispatch = useAppDispatch();
  const countries = useAppSelector((state) => state.inmutable.countries);
  const venCountry = countries.find(
    (c: CountryInterface) => c.countryCodeIso === "VEN"
  );

  // Fetch countries and set them in the store
  useEffect(() => {
    const findCountries = async () => {
      const countries = await getCountries();
      if (countries) {
        dispatch(setInmutableCountries(countries));
      }
    };

    findCountries();
  }, [dispatch]);

  // Fetch app data and set them in the store
  useEffect(() => {
    const getAppData = async () => {
      const response = await getBaseAppData(venCountry?.id || null);

      if (!response.didError && !!response.model) {
        const appDataDto = response.model;
        // Set App Data
        dispatch(
          setInmutableAppData({
            applicationName: appDataDto.applicationName,
            applicationID: appDataDto.applicationID,
            applicationDescription: appDataDto.applicationDescription,
            applicationCode: appDataDto.applicationCode,
          } as AppDataInterface)
        );
        // Set Exchange Rate
        if (!!appDataDto.exchangeRate) {
          dispatch(setInmutableExchangeRate(appDataDto.exchangeRate));
        }
        // Set Business Services
        if (!!appDataDto.businessServiceList) {
          dispatch(
            setInmutableBusinessServices(appDataDto.businessServiceList)
          );
        }
        // Set Rate Item List
        if (!!appDataDto.rateItemList) {
          dispatch(setInmutableRateItems(appDataDto.rateItemList));
        }
        // Set Piece Categories
        if (!!appDataDto.shippingCategoryList) {
          const categories = appDataDto.shippingCategoryList.map((c) => ({
            id: c.categoryID,
            name: c.categoryName,
            code: c.categoryCode,
            descriptionTags: c.descriptionTags,
          }));
          dispatch(setInmutablePieceCategories(categories));
        }
        // Set Currency List
        if (!!appDataDto.currencyList) {
          dispatch(setInmutableCurrencyList(appDataDto.currencyList));
        }
        // Set Payment Mode List
        if (!!appDataDto.paymentModeList) {
          dispatch(setInmutablePaymentModes(appDataDto.paymentModeList));
        }
        // Set Range Declare List
        if (!!appDataDto.rangeDeclareList) {
          dispatch(setInmutableRangeDeclareList(appDataDto.rangeDeclareList));
        }
      } else {
        alertService.error(
          "Error al cargar los datos de la aplicación",
          response.errorMessage,
          { autoClose: false }
        );
      }
    };
    getAppData();
  }, [dispatch, venCountry?.id]);

  // Fetch stores
  useEffect(() => {
    const getAllBusinessUnits = async () => {
      const businessUnits = await getBusinessUnits();

      if (!!businessUnits) {
        dispatch(setInmutableBusinessUnits(businessUnits));
      }
    };

    getAllBusinessUnits();
  }, [dispatch]);

  return (
    <Routes>
      {AppRoutes.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}
      <Route path="*" element={<NotFound />} />
      <Route path="/*/:shipmentNotFound" element={<NotFound />} />
    </Routes>
  );
};

export default App;
