import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Undefinable,
  PaymentMode,
  TaxInterface,
  DeliveryType,
  ShipmentService,
  AccountInterface,
  PaymentInterface,
  ShipmentInterface,
  DocumentInterface,
  ShipmentStatus,
} from "../../interfaces";
import { CouponDTO } from "../../interfaces/Dtos/CouponDTO";

export enum ShipmentCreatePhase {
  SHIPMENTS,
  DOCUMENT,
  PAYMENT,
  RESUME,
}

interface ShipmentCreateState {
  coupon?: CouponDTO;
  taxes: TaxInterface[];
  owner?: AccountInterface;
  phase: ShipmentCreatePhase;
  document?: DocumentInterface;
  payments: PaymentInterface[];
  shipments: ShipmentInterface[];
  shipmentDrafts: Partial<ShipmentInterface>[];
}

interface ShipmentCreateInterfaceAction {
  index: number;
  shipment: Partial<ShipmentInterface>;
}

const initialState: ShipmentCreateState = {
  shipments: [],
  shipmentDrafts: [
    {
      pieces: [],
      items: [],
      deliveryType: DeliveryType.AT_OFFICE,
      paymentMode: PaymentMode.COD,
      service: ShipmentService.STANDARD,
      status: ShipmentStatus.DRAFT,
    },
  ],
  phase: ShipmentCreatePhase.SHIPMENTS,
  payments: [],
  taxes: [],
};

export const shipmentCreateSlice = createSlice({
  name: "pickup",
  initialState,
  reducers: {
    setShipmentCreateList: (
      state,
      action: PayloadAction<ShipmentInterface[]>
    ) => {
      state.shipments = action.payload;
    },
    setShipmentCreateDrafts: (
      state,
      action: PayloadAction<Partial<ShipmentInterface>[]>
    ) => {
      state.shipmentDrafts = action.payload;
    },
    setShipmentCreateDraft(
      state,
      action: PayloadAction<ShipmentCreateInterfaceAction>
    ) {
      const { index, shipment } = action.payload;
      const currentDraft = state.shipmentDrafts[index];
      state.shipmentDrafts[index] = { ...currentDraft, ...shipment };
    },
    setShipmentCreatePhase: (
      state,
      action: PayloadAction<ShipmentCreatePhase>
    ) => {
      state.phase = action.payload;
    },
    setShipmentCreateOwner: (
      state,
      action: PayloadAction<Undefinable<AccountInterface>>
    ) => {
      state.owner = action.payload;
    },
    setShipmentCreatePayments: (
      state,
      action: PayloadAction<PaymentInterface[]>
    ) => {
      state.payments = action.payload;
    },
    setShipmentCreateTaxes: (state, action: PayloadAction<TaxInterface[]>) => {
      state.taxes = action.payload;
    },
    setShipmentCreateDocument: (
      state,
      action: PayloadAction<DocumentInterface>
    ) => {
      state.document = action.payload;

      // Set the other data
      state.owner = action.payload.accountOwner;
      state.taxes = action.payload.taxes;
      state.payments = action.payload.payments;
      state.shipments = action.payload.shipments as ShipmentInterface[];
    },
    setShipmentCreateCoupon: (
      state,
      action: PayloadAction<CouponDTO | undefined>
    ) => {
      state.coupon = action.payload;
    },
    clearShipmentCreate: (state) => {
      state.payments = [];
      state.shipments = [];
      state.taxes = [];
      state.document = undefined;
      state.owner = undefined;
      state.coupon = undefined;
      state.phase = ShipmentCreatePhase.SHIPMENTS;
      state.shipmentDrafts = [
        {
          pieces: [],
          items: [],
          service: ShipmentService.STANDARD,
          deliveryType: DeliveryType.AT_OFFICE,
          paymentMode: PaymentMode.COD,
          status: ShipmentStatus.DRAFT,
        },
      ];
    },
  },
});

export const {
  clearShipmentCreate,
  setShipmentCreateList,
  setShipmentCreateDraft,
  setShipmentCreateTaxes,
  setShipmentCreateCoupon,
  setShipmentCreateDocument,
  setShipmentCreatePhase,
  setShipmentCreateOwner,
  setShipmentCreateDrafts,
  setShipmentCreatePayments,
} = shipmentCreateSlice.actions;

export default shipmentCreateSlice.reducer;
