import { ReactNode, FC, ButtonHTMLAttributes } from "react";
import classNames from "classnames";
import {
  PlusIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";

const buttonClassname =
  "inline-flex justify-center rounded-md px-3 py-2 " +
  "text-sm font-semibold shadow-sm " +
  "focus-visible:outline focus-visible:outline-2 " +
  "focus-visible:outline-offset-2 border-2 border-indigo-600";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
}
export const PrimaryButton: FC<ButtonProps> = ({
  children,
  className,
  disabled,
  ...props
}) => {
  return (
    <button
      className={classNames(
        buttonClassname,
        "bg-indigo-600 hover:bg-indigo-500 text-white focus-visible:outline-indigo-500",
        className,
        disabled ? "pointer-events-none opacity-50" : ""
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export const SecondaryButton: FC<ButtonProps> = ({
  children,
  className,
  disabled,
  ...props
}) => {
  return (
    <button
      className={classNames(
        buttonClassname,
        className,
        disabled ? "pointer-events-none opacity-50" : "",
        "bg-white hover:bg-gray-200 focus-visible:outline-indigo-500 text-indigo-600"
      )}
      {...props}
    >
      {children}
    </button>
  );
};

interface NextButtonProps extends ButtonProps {}
export const NextButton: FC<NextButtonProps> = ({ className, ...props }) => {
  return (
    <PrimaryButton {...props} className={`pl-4 ${className}`}>
      Siguiente
      <ChevronRightIcon
        className="h-5 w-5 flex-none text-gray-400"
        aria-hidden="true"
      />
    </PrimaryButton>
  );
};

interface BackButtonProps extends ButtonProps {
  handleOnRegret: () => void;
}
export const BackButton: FC<BackButtonProps> = ({
  handleOnRegret,
  ...props
}) => {
  return (
    <PrimaryButton onClick={() => handleOnRegret()} className="pr-4" {...props}>
      <ChevronLeftIcon
        className="h-5 w-5 flex-none text-gray-400"
        aria-hidden="true"
      />
      Regresar
    </PrimaryButton>
  );
};

interface AddButtonProps extends ButtonProps {}
export const AddButton: FC<AddButtonProps> = ({ ...props }) => {
  return (
    <PrimaryButton {...props} className="pr-4">
      <PlusIcon className="h-5 w-5 flex-none text-white" aria-hidden="true" />
      Agregar
    </PrimaryButton>
  );
};

interface EditButtonProps extends ButtonProps {}
export const EditButton: FC<EditButtonProps> = ({ ...props }) => {
  return (
    <PrimaryButton {...props} className="pr-4">
      <PencilSquareIcon
        className="mr-1 h-5 w-5 flex-none text-white"
        aria-hidden="true"
      />
      Editar
    </PrimaryButton>
  );
};

interface LinkTextProps {
  text: string;
  disabled?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}
export const LinkText: FC<LinkTextProps> = ({
  text,
  disabled,
  className,
  onClick,
}) => {
  return (
    <span
      onClick={onClick}
      className={classNames(
        "text-blue-500 focus:outline-none",
        disabled
          ? "opacity-50 pointer-events-none"
          : "hover:underline hover:cursor-pointer",
        className
      )}
    >
      {text}
    </span>
  );
};
