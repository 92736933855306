import api from "./api";
import { Nullable } from "../interfaces/GenericTypes";
import {
  CreateShipmentDHLResponse,
  PickupBookingDHLResponse,
  RatesDHLResponse,
  TrackingDHLResponse,
  DHLErrorResponse,
  DhlPackageDto,
  DhlLineItemsDto,
} from "../interfaces/DHLInterface";

const baseURL = `${process.env.REACT_APP_API_HOST}/DHL`;

const header = {
  headers: {
    "Content-Type": "application/json",
  },
};

// Function to get DHL rates
export const getRateDHL = async (
  plannedShippingDate: Date,
  originCountryCode: string,
  originPostalCode: string,
  originCityName: string,
  destinationCountryCode: string,
  destinationPostalCode: string,
  destinationCityName: string,
  weight: number,
  length: number,
  width: number,
  height: number
): Promise<Nullable<RatesDHLResponse>> => {
  const date = new Date(plannedShippingDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const plannedShippingDateString = `${year}-${month}-${day}`;

  try {
    const response = await api.get(
      `${baseURL}/Rates?originCountryCode=${originCountryCode}&originPostalCode=${originPostalCode}&originCityName=${originCityName}&destinationCountryCode=${destinationCountryCode}&destinationPostalCode=${destinationPostalCode}&destinationCityName=${destinationCityName}&weight=${weight}&length=${length}&width=${width}&height=${height}&plannedShippingDate=${plannedShippingDateString}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(response); // DEBUG
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error); // DEBUG
    const errorResponse = error as DHLErrorResponse;
    alert(errorResponse.response.data.detail);
  }

  return null;
};

// Function to get DHL rates for multiple packages
export const getRatesDHL = async (
  plannedShippingDateAndTime: Date,
  shipperPostalCode: string,
  shipperCityName: string,
  shipperCountryCode: string,
  shipperAddressLine1: string,
  shipperAddressLine2: string,
  shipperAddressLine3: string,
  receiverPostalCode: string,
  receiverCityName: string,
  receiverCountryCode: string,
  receiverAddressLine1: string,
  receiverAddressLine2: string,
  receiverAddressLine3: string,
  packages: DhlPackageDto[],
  declaredValue: number,
  isCustomsDeclarable: boolean
): Promise<Nullable<RatesDHLResponse>> => {
  const date = new Date(plannedShippingDateAndTime);
  const year = date.getFullYear();
  const month = "" + (date.getMonth() + 1);
  const day = "" + (date.getDate() + 1);
  const plannedPickupDateAndTimeString = `${year}-${
    month.length < 2 ? "0" + month : month
  }-${day.length < 2 ? "0" + day : day}T08:00:00 GMT-04:00`;

  const body = {
    plannedShippingDateAndTime: plannedPickupDateAndTimeString,
    shipperPostalCode: shipperPostalCode,
    shipperCityName: shipperCityName,
    shipperCountryCode: shipperCountryCode,
    shipperAddressLine1: shipperAddressLine1,
    shipperAddressLine2: shipperAddressLine2,
    shipperAddressLine3: shipperAddressLine3,
    receiverPostalCode: receiverPostalCode,
    receiverCityName: receiverCityName,
    receiverCountryCode: receiverCountryCode,
    receiverAddressLine1: receiverAddressLine1,
    receiverAddressLine2: receiverAddressLine2,
    receiverAddressLine3: receiverAddressLine3,
    packages: packages,
    declaredValue: declaredValue,
    isCustomsDeclarable: isCustomsDeclarable,
  };

  try {
    const response = await api.post(`${baseURL}/Rates`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    const errorResponse = error as DHLErrorResponse;
    console.log(errorResponse.response.data); // DEBUG
    alert(errorResponse.response.data.detail);
  }

  return null;
};

// Function to create DHL shipment
export const createShipmentDHL = async (
  plannedShippingDateAndTime: Date,
  shipperPostalCode: string,
  shipperCityName: string,
  shipperCountryCode: string,
  shipperAddressLine1: string,
  shipperAddressLine2: string,
  shipperAddressLine3: string,
  shipperPhone: string,
  shipperFullName: string,
  shipperCompanyName: string,
  receiverPostalCode: string,
  receiverCityName: string,
  receiverCountryCode: string,
  receiverAddressLine1: string,
  receiverAddressLine2: string,
  receiverAddressLine3: string,
  receiverEmail: string,
  receiverPhone: string,
  receiverFullName: string,
  receiverCompanyName: string,
  shipmentDescription: string,
  packages: DhlPackageDto[],
  isCustomsDeclarable: boolean,
  lineItems: DhlLineItemsDto[],
  invoiceNumber: string
): Promise<Nullable<CreateShipmentDHLResponse>> => {
  // shipping date
  const date = new Date(plannedShippingDateAndTime);
  const year = date.getFullYear();
  const month = "" + (date.getMonth() + 1);
  const day = "" + (date.getDate() + 1);
  const plannedPickupDateAndTimeString = `${year}-${
    month.length < 2 ? "0" + month : month
  }-${day.length < 2 ? "0" + day : day}T08:00:00 GMT-04:00`;

  // invoice date
  const date2 = new Date();
  const year2 = date2.getFullYear();
  const month2 = "" + (date2.getMonth() + 1);
  const day2 = "" + (date2.getDate() + 1);
  const invoiceDateString = `${year2}-${
    month2.length < 2 ? "0" + month2 : month2
  }-${day2.length < 2 ? "0" + day2 : day2}`;

  // declared value is the sum of all line items prices
  const declaredValue = lineItems.reduce(
    (total, item) => total + item.price * item.quantity.value,
    0
  );

  const body = {
    plannedShippingDateAndTime: plannedPickupDateAndTimeString,
    shipperPostalCode: shipperPostalCode,
    shipperCityName: shipperCityName,
    shipperCountryCode: shipperCountryCode,
    shipperAddressLine1: shipperAddressLine1,
    shipperAddressLine2: shipperAddressLine2,
    shipperAddressLine3: shipperAddressLine3,
    shipperPhone: shipperPhone,
    shipperCompanyName: shipperCompanyName,
    shipperFullName: shipperFullName,
    receiverPostalCode: receiverPostalCode,
    receiverCityName: receiverCityName,
    receiverCountryCode: receiverCountryCode,
    receiverAddressLine1: receiverAddressLine1,
    receiverAddressLine2: receiverAddressLine2,
    receiverAddressLine3: receiverAddressLine3,
    receiverEmail: receiverEmail,
    receiverPhone: receiverPhone,
    receiverCompanyName: receiverCompanyName,
    receiverFullName: receiverFullName,
    shipmentDescription: shipmentDescription,
    packages: packages,
    isCustomsDeclarable: isCustomsDeclarable,
    declaredValue: declaredValue,
    lineItems: lineItems,
    invoiceNumber: invoiceNumber,
    invoiceDate: invoiceDateString,
  };

  try {
    const response = await api.post(`${baseURL}/Shipment`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    const errorResponse = error as DHLErrorResponse;
    console.log(errorResponse.response.data); // DEBUG
    alert(errorResponse.response.data.detail);
  }

  return null;
};

// Function to get DHL tracking
export const getTrackingDHL = async (
  trackingNumber: string
): Promise<Nullable<TrackingDHLResponse>> => {
  try {
    const response = await api.get(
      `${baseURL}/Tracking?shipmentTrakingNumber=${trackingNumber}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    const errorResponse = error as DHLErrorResponse;
    console.log(errorResponse.response.data); // DEBUG
    alert(errorResponse.response.data.detail);
  }

  return null;
};

// Function to create DHL pickup booking
export const createPickupBookingDHL = async (
  plannedPickupDateAndTime: Date,
  packages: DhlPackageDto[],
  isCustomsDeclarable: boolean
): Promise<Nullable<PickupBookingDHLResponse>> => {
  const date = new Date(plannedPickupDateAndTime);
  const year = date.getFullYear();
  const month = "" + (date.getMonth() + 1);
  const day = "" + (date.getDate() + 1);
  const plannedPickupDateAndTimeString = `${year}-${
    month.length < 2 ? "0" + month : month
  }-${day.length < 2 ? "0" + day : day}T08:00:00 GMT-04:00`;

  const body = {
    plannedPickupDateAndTime: plannedPickupDateAndTimeString,
    packages: packages,
    isCustomsDeclarable: isCustomsDeclarable,
  };

  try {
    const response = await api.post(`${baseURL}/Pickup`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return response.data as PickupBookingDHLResponse;
    }
  } catch (error) {
    const errorResponse = error as DHLErrorResponse;
    console.log(errorResponse.response.data); // DEBUG
    alert(errorResponse.response.data.detail);
  }
  return null;
};

// Function to update DHL pickup booking
export const updatePickupBookingDHL = async (
  dispatchConfirmationNumber: string,
  plannedPickupDateAndTime: Date, // = "2023-11-23T19:19:40 GMT+00:00",
  receiverCityName: string, // = "Graford",
  receiverCountryCode: string, // = "US",
  receiverPostalCode: string, // = "76449",
  receiverAddressLine1: string, // = "116 Marine Dr",
  receiverEmail: string, // = "recipient_create_shipmentapi@dhltestmail.com",
  receiverPhone: string, // = "9402825665",
  receiverFullName: string // = "Baylee Marshall"
): Promise<Nullable<PickupBookingDHLResponse>> => {
  const date = new Date(plannedPickupDateAndTime);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate() + 1;
  const plannedPickupDateAndTimeString = `${year}-${month}-${day}T08:00:00 GMT-04:00`;

  try {
    const response = await api.patch(
      `${baseURL}/Pickup?dispatchConfirmationNumber=${dispatchConfirmationNumber}&plannedPickupDateAndTime=${plannedPickupDateAndTimeString}&receiverCityName=${receiverCityName}&receiverCountryCode=${receiverCountryCode}&receiverPostalCode=${receiverPostalCode}&receiverAddressLine1=${receiverAddressLine1}&receiverEmail=${receiverEmail}&receiverPhone=${receiverPhone}&receiverFullName=${receiverFullName}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    const errorResponse = error as DHLErrorResponse;
    alert(errorResponse.response.data.detail);
  }
  return null;
};

// Function to cancel DHL pickup booking
export const cancelPickupBookingDHL = async (
  dispatchConfirmationNumber: string,
  reason: string
): Promise<Nullable<PickupBookingDHLResponse>> => {
  try {
    const response = await api.delete(
      `${baseURL}/Pickup?dispatchConfirmationNumber=${dispatchConfirmationNumber}&reason=${reason}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    const errorResponse = error as DHLErrorResponse;
    alert(errorResponse.response.data.detail);
  }
  return null;
};
